.FormDateInp {
    /*width: 150px;*/
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    width: 150px;
    height: 70px;

}

.FormDateBody {
    width: 375px;
    display: block;
    margin: 20px
    /*display: block;*/
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .FormDateBody {
        margin: 0;
        width: 100%;
    }

    .FormDateInp {
        /*margin: 0;*/
        width: 100%;
        margin: 0;

        /*display: inline;*/
    }
}