.profileUserBlok{
    width: 1000px;
    height: 100%;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
}
.logoProfile{
    width: 300px;
}
.profileUserContent{
    box-shadow: 0 4px 7px 0 rgb(0 0 0 / 18%), 0 4px 10px 0 rgb(0 0 0 / 14%);

    justify-items: center;
    width: 600px;
    height: 700px;
    border-radius: 8px;
    background-color: #ffffff;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 200px;
    margin-right: 30px;
}

.headerProfile{
    border-top-left-radius: 8px; /* Левый верхний уголок */
    border-top-right-radius: 8px; /* Правый верхний уголок */
    width: 600px;
    height: 160px;
    background-color: #007fff;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .logoProfile{
        width: 279px;
    }
}