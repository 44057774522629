.lastBlock {
    display: flex;
    margin-left: 25px;
}

.ratingBlock {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

button.animate {
    transition: transform 0.2s;
}

button.animate:active {
    transform: scale(0.95);
}

.fade-in {
    opacity: 0; /* Начальное состояние - невидимый */
    transform: translateY(-10px); /* Начальное состояние - сдвиг наверх */
    animation: fadeIn 2s ease-in-out forwards; /* Анимация */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}