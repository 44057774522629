.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;
  list-style: none;
  user-select: none;
}
.pagination-btn{
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

  display: inline-block;
  background: #007fff;
  border: 6px;
  border-radius: 6px;
  border-color: #000000;
  width: 30px;
  height: 30px;
  margin:  4px 4px 4px 4px;
  font-size: 1em;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.pagination-arrow {
  margin: 5px 24px 0;
  cursor: pointer;
  display: none;
}

.disabled {
  cursor: not-allowed;
}
.path {
  stroke: #ccc;
}
.path {
  stroke: black;
}
.chart-wrapper {
  height: 0vh;
}