.buttonOpenTask{
    border: none;
    background-image: none;
    margin: 0;
    width: 30px;

}
.buttonOpenTaskTag{
    width: 45px;
    margin: 0;
    border: none;
    background-color: transparent;
    border-color: #ccc
}
.headerTask{
    font-size: 13pt;
        vertical-align: middle;
    margin: 0;

}

.alignDisp{
    display:inline-block;
}
.pArg{
    display:inline-block;
    font-size: 12pt;
    font-family: Arial;
    width: 40%

}
.pArg2{
    display:inline-block;
    font-size: 11pt;
    font-family: Arial;

}
.pArg3{
 display:inline-block;
    font-size: 12pt;
    font-family: Arial;


}
.widthForm{
    width: 100%;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
.pArg{
    display: block;
    font-size: 12pt;
    font-family: Arial;
    font-style: initial;
    color: #0a53be;
    width: 100%

}
.pArg2{
    display:inline-block;
    font-size: 11pt;
    font-family: Arial;

}
.pArg3{
    width: 300px;
}
}