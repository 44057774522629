@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Exo', sans-serif;
}


.context {
    width: 100%;
    position: relative;

}

.logo{
    position: absolute;
    left: 35%;
    width: 30%;
    font-size: 20px;
    top:200px;
    animation: 5s anim-lineUp ease-out ;
}

.context h1{
    position: absolute;
    text-align: center;
    color: #fff;
    font-size: 50px;
    top:370px;
    animation: 7s anim-lineUp ease-out ;


}

.button{
    position: absolute;
    left: 45%;
    width: 10%;
    font-size: 20px;
    border-color: white;
    top:450px;
    animation: 9s anim-lineUp ease-out ;
    justify-content: center;
    z-index: 1;


}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.area{
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(35,158,242,1) 0%, rgba(7,0,116,1) 99%);
    width: 100%;
    height:900px;



}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
.logo{
width: 100%;
     position: absolute;
    /*left: auto;*/
    /*margin-left: auto;*/
    /*display: none;*/
    z-index: 10000;
    left: 0;
  padding: 10px;

    font-size: 20px;
    top:150px;
    animation: 5s anim-lineUp ease-out ;
}
    .area{
        height: 500px;

    }
    .circles{
        /*position: static;*/
        height: 500px;
        padding: 0;
    }
    .context h1{
    position: absolute;
    text-align: center;
    color: #fff;
    font-size: 20px;
    top:300px;
    animation: 7s anim-lineUp ease-out ;


}
    .button{
    position: absolute;
    left: 36%;
    width: 30%;
    font-size: 15px;
    border-color: white;
        padding: 0;
    top:360px;
    animation: 9s anim-lineUp ease-out ;
    justify-content: center;
    z-index: 1;


}
}