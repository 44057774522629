.mailNewTask{
 display:inline-block;

}

.newTaskElement{
    margin-left: 10px;
  float: left;
}
.TaskComp{
    border: none;
    background-color: #ffffff;
}
.checkmark__circle {
  stroke-dasharray: 216; /* ORIGINALLY 166px */
  stroke-dashoffset: 216; /* ORIGINALLY 166px */
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}


.checkmark {
  width: 106px; /* ORIGINALLY 56px */
  height: 106px; /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 0% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 98; /* ORIGINALLY 48px */
  stroke-dashoffset: 98; /* ORIGINALLY 48px*/
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 80px #7ac142;
  }
}