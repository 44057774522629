/*Лодер*/
.bodyLoader{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index:99;
    background-color: rgba(217, 215, 215, 0.58);
}
/*.titleLoad{*/
    /*margin: auto;*/
    /*top: 65%;*/
    /*right: 0;*/
    /*bottom: 0;*/
    /*color: white;*/
    /*left: 0;*/
    /*text-align: center;*/
    /*z-index: +1;*/
    /*position: absolute;*/
/*}*/
.coin {
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;

    --diameter:230px;
    --thickness:1px;
    --scale:0.75;
    --period:3s;

    /*--gradient: linear-gradient(gold 0%, yellow 50%, gold 100%);*/

    transform-style: preserve-3d;
    width: var(--diameter);
    height: var(--diameter);
    animation: spin var(--period) linear infinite;
}

.side {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(13, 110, 253, 0.97);
    display: flex;
    align-items: center;
    justify-content: center;
    color: yellow;
    font-size: calc(var(--diameter) * 0.6);
    font-weight: bold;
}

.head {
    transform: translateZ(calc(var(--thickness) / 2));
    animation: flip-head var(--period) linear infinite;
}

.tail {
    transform:
            translateZ(calc(0px - var(--thickness) / 2))
            rotateY(180deg);
    animation: flip-tail var(--period) linear infinite;
}

.edge {
    position: absolute;
    width: var(--thickness);
    height: 100%;
    transform:
            translateX(calc(var(--diameter) / 2))
            translateZ(calc(var(--thickness) / 2))
            rotateY(90deg);
    transform-origin: 0 0;
    background-color: rgba(13, 110, 253, 0.97);
    background-image: var(--gradient);
}

@keyframes spin {
    0% {
        transform: rotateY(0deg) scale(var(--scale));
    }
    100% {
        transform: rotateY(360deg) scale(var(--scale));
    }
}

@keyframes flip-tail {
    0% {
        background-image: var(--gradient);
        color: transparent;
    }
    50% {
        background-image: none;
        color: yellow;
    }
    100% {
        background-image: var(--gradient);
        color: transparent;
    }
}

@keyframes flip-head {
    0% {
        background-image: none;
        color: yellow;
    }
    50% {
        background-image: var(--gradient);
        color: transparent;
    }
    100% {
        background-image: none;
        color: yellow;
    }
}