.header{
    background-color: #ffffff;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

    color: #151313;
    display: flex ;
    justify-content: center;
    width: 100%;
    position: sticky;
    z-index: 1;


}
.navBar{
    width: 1140px;
    display: flex ;
    justify-content: center;

}
.navbarBrandLogo{
    width: 150px;

}
.navbarBrand{
    width: 150px;
    color: black;
}

.navLink{
    color: #2f2f2f;
    margin-top: 10px;
    font-weight: bold;
}
.navLinkLogout{
    color: #2f2f2f;
    margin-top: 10px;
    font-weight: bold;
    margin-left: 400px
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .navLinkLogout{
        font-size: 12px;
        margin-top: 5px;
        margin-left: 0px

}
    .navLink{
        font-size: 12px;
        margin-top: 5px;


    }
    .navBar{
        width: 100%;


    }
    .header{
             width: 100%;

    }

    .navbarBrandLogo{
        width: 80px;
        margin-left: 10px;

    }
    .navbarBrand{
     width: 80px;
    }
}
