.inputBorder{
/*display: inline-block;*/
      float: right;
    display: flex;

}
.input{
    width: 300px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 40px;

      float: left;


}
.buttonSearch{
    float: left;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
        margin-left: 3px;

    height: 40px;
}

.buttonSearchDel{
        float: left;
    margin-left: 0px;
    border-radius: 0;
    /*border-bottom-left-radius: 0;*/
    height: 40px;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .input{
    width: 200px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 40px;

      float: left;


}
    .inputBorder{
        /*margin-right: 20px;*/
        float: left;
        margin-left: 10px;
    }
}