.TabelPad {
    margin-left: 20px;
    margin-top: 20px;
}

.newTask{
    width: 50px;
}
.ButtonNewTask{
    margin-bottom: 10px;
    margin-left: 10px;
}

.buttonTask{
    border-color: #ffffff;
    background-color: #ffffff;
    color: black;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
height: 70px;
}

.allTabs{
    font-family: Arial;

}
.tabelContent {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 8px;
    width: 930px;
    margin-top: 10px;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
}
.headerTabel{
    vertical-align: middle;
    background-color: #0495fd;
    color: #ffffff;
    text-align: center;

}
.headerTabelButton{
    height: 30px;
    font-weight: bold;
    border: none;
     background-color: #0495fd;
    color: #ffffff;
    text-align: center;
}
.DropButtonStatus{
border: none;
    /*border: none;*/
      text-overflow: ellipsis;
  max-width: 200px;
    white-space: nowrap;
  overflow: hidden;

   color: #FFFFFF;
    /*border-color: transparent;*/
    font-weight: bold;


}

.DropButtonStatus :hover :active{
    border: none;

}
.numberTask{
    max-width: 60px;
    vertical-align: middle;
    text-align: center;


}

.tabName{
    max-width:100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis}

.formulation_task{

    max-width:200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.counterparty{
  max-width:100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}
.date{
    width: 150px;
    vertical-align: middle;
    text-align: center;

}
.status{
    max-width:120px;
    vertical-align: middle;
    text-align: left;

    padding-left: 20px;
    /*border: none;*/
}
.openTask{
    margin: 0;
    width: 40px;
border-left: none;
}


.TextField {

border: none;

    margin: 0;
resize: none ;


    overflow: auto;
    font-family: "Arial";
}

.TextField::-webkit-scrollbar {
  width: 5px;
}

.TextField::-webkit-scrollbar-track {
  background-color:#edeadf;
  border-radius: 10px;

}

.TextField::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #128ae7;

}

.TextField::-webkit-scrollbar {
  width: 5px;


}

.TextField::-webkit-scrollbar-track {
  background-color: #d7cfcf;
  border-radius: 10px;

}

.TextField::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #0494fc;

}
.TextField:disabled{
    background-color: transparent;

}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .tabelContent {

    width: 100%;
    /*padding: 0px;*/
    border-radius: 0;
    padding-top: 10px;
         margin-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 30px;
        box-shadow: none;
        margin-bottom: 15px;
    }

    .numberTask{
        width: 100%;
        vertical-align: middle;
        text-align: center;
        display: none;


}

.formulation_task{
        width: 100%;
        height: 10px;
}
.counterparty{
        width: 100%;
}
.date{
    width: 40%;
    font-size: 13px;
    text-align: center;
}
.status{
    width: 100%;
    font-size: 13px;
}
.TextField{
      padding: 0;
      /*min-height: 30px;*/
      height: 100%;
      width: 100%;
      font-size: 13px;
}
.NumHeader{
    display: none;
}
.headerTabel{

        width: 100%;
}
.allTabs{
 }
.listAll{
    font-size: 10px;
}
}