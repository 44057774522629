.container-my {
    background-color: #ffffff;
    width: 30%;
    height: 40%;
    padding-bottom: 150px;
    padding-top: 15px;
    border-radius: 8px;
    left: 35%;
    font-size: 20px;
    border-color: white;
    top:30%;
    justify-content: center;
    position: absolute;
    z-index: 1;
}
.upPass{
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 10px;

}
.form {
    background-color: rgba(255, 255, 255, 0.57);
    position: absolute;
    width: 500px;
    height: 55%;
    margin: 10% auto;
    z-index: 1;
    justify-content: center;
    left: 35%;
    border-radius: 8px;
}
.formAuthDiv{
justify-content: center;
    text-align: center;
}
.inputPhone{
    width: 400px;
}
.h2 {
    /*margin-top: 10px;*/
    color: #000000;
    font-size: 25px;
    padding-bottom: 0px;
    text-align: left;
    margin-left: 10%;
    margin-top: 5%;
}

.p {
    color: #bf1650;
    text-align: left;
    margin-left: 15%;
}

textarea,
select,
input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
}

.label,
section > label {
    line-height: 2;
    display: block;
    margin-bottom: 13px;

    margin-top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 200;
}

input[type="submit"],
.buttonFormAut {
    position: relative;
    background: #224cbd;
    color: white;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    letter-spacing: 2px;
    display: block;
    left: 15%;
    appearance: none;
    border-radius: 4px;
    width: 70%;
    font-weight: 400;
    letter-spacing: 0.5rem;

    transition: 0.3s all;
}
.buttonFormReg {
    position: relative;
    background: linear-gradient(45deg, rgba(8, 211, 172, 0.45) 0%, rgba(8, 211, 172, 0.45) 12.5%,rgba(62, 29, 50, 0.45) 12.5%, rgba(62, 29, 50, 0.45) 25%,rgba(54, 55, 67, 0.45) 25%, rgba(54, 55, 67, 0.45) 37.5%,rgba(47, 81, 85, 0.45) 37.5%, rgba(47, 81, 85, 0.45) 50%,rgba(23, 159, 137, 0.45) 50%, rgba(23, 159, 137, 0.45) 62.5%,rgba(16, 185, 155, 0.45) 62.5%, rgba(16, 185, 155, 0.45) 75%,rgba(31, 133, 120, 0.45) 75%, rgba(31, 133, 120, 0.45) 87.5%,rgba(39, 107, 102, 0.45) 87.5%, rgba(39, 107, 102, 0.45) 100%),linear-gradient(135deg, rgb(87, 116, 221),rgb(35, 4, 229));
    color: white;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    letter-spacing: 2px;
    display: block;
    left: 15%;
    appearance: none;
    border-radius: 4px;
    width: 70%;
    /*font-weight: 400;*/
    /*letter-spacing: 0.5rem;*/

    transition: 0.3s all;
}
.buttonBlack {
    background: black;
    border: 1px solid white;
}


.container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: 20px;
}

.counter {
    font-weight: 400;
    background: white;
    color: black;
    padding: 10px 15px;
    border-radius: 4px;
    position: fixed;
    top: 20px;
    right: 30px;

    z-index: 9999999;
}

buttonForm[type="button"] {
    padding: 5px;
    background: #516391;
    color: white;
    letter-spacing: 0px;
    text-transform: none;
    padding: 10px;
    letter-spacing: 1px;
}

input[type="submit"]:hover,
buttonForm[type="button"]:hover {
    background: #bf1650;
    color: white;
}

input[type="submit"]:active {
    transition: 0.3s all;
    top: 2px;
}

.preact {

    opacity: 0;
    color: white;
}
.FormAnataation{
    text-align: center;
    color: #ffffff;
}

.preact:hover {
    opacity: 1;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .FormAnataation{
    display: none;
    }
    
    .container-my {
    background-color: #ffffff;
    width: 30%;
    height: 40%;
    padding-bottom: 150px;
    padding-top: 15px;
    border-radius: 8px;
    left: 35%;
    font-size: 20px;
    border-color: white;
    top:30%;
    justify-content: center;
    position: absolute;
    z-index: 1;
}

.form {
    /*margin: 0 auto;*/
    background-color: rgba(255, 255, 255, 0.57);
    position: absolute;
    width: 100%;
    height: 450px;
/*padding: 10px;*/
    z-index: 1;
    justify-content: center;
    left: 0;
    /*margin: 10%;*/

    border-radius: 0px;
}

.h2 {
    /*margin-top: 10px;*/
    color: #000000;
    font-size: 25px;
    padding-bottom: 0px;
    text-align: left;
    left: 0;
    margin-top: 5%;
}

.p {
    color: #bf1650;
    text-align: left;
    margin-left: 15%;
}

textarea,
select,
input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
}

.label,
section > label {
    line-height: 2;
    display: block;
    margin-bottom: 13px;

    margin-top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 200;
}

input[type="submit"],
.buttonFormAut {
    position: relative;
    background: #224cbd;
    color: white;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    margin-top: 30px;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 2px;
    display: block;
    left: 0;
    appearance: none;
    border-radius: 4px;
    width: 300px;
    margin:  auto;

    font-weight: 400;

    letter-spacing: 0.5rem;

    transition: 0.3s all;
}
.buttonFormReg {
    position: relative;
    background: #07679b;
    font-weight: 400;
    color: white;
    text-transform: uppercase;
    border: none;
    /*font-weight: 600;*/

    margin-bottom: 30px;
    padding: 10px;
    font-size: 16px;
    letter-spacing: 2px;
    margin: auto;

    display: block;
    left: 0;
    appearance: none;
    border-radius: 4px;
    width: 300px;
    /*font-weight: 400;*/
    /*letter-spacing: 0.5rem;*/

    transition: 0.3s all;
}
.buttonBlack {
    background: black;
    border: 1px solid white;
}


.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

}

.counter {
    font-weight: 400;
    background: white;
    color: black;
    padding: 10px 15px;
    border-radius: 4px;
    position: fixed;
    top: 20px;
    right: 30px;

    z-index: 9999999;
}

buttonForm[type="button"] {
    padding: 5px;
    background: #516391;
    color: white;
    letter-spacing: 0px;
    text-transform: none;
    padding: 0px;
    letter-spacing: 1px;
}

input[type="submit"]:hover,
buttonForm[type="button"]:hover {
    background: #bf1650;
    color: white;
}

input[type="submit"]:active {
    transition: 0.3s all;
    top: 2px;
}

.preact {

    opacity: 0;
    color: white;
}

.preact:hover {
    opacity: 1;
}
.inputPhone{
    width: 300px;
}
.formAuthDiv{
    margin: 0 auto;
    width: 100%;

    /*width: 200px;*/
    /*margin: 0;*/
    /*margin: 18px;*/
    /*padding: 40px;*/
    padding-top: 10px;


}
}