/*.footer {*/
/*    display: flex ;*/
/*    justify-content: center;*/
/*    background-color: #363b3f;*/
/*    padding-bottom: 0;*/
/*    clear: bottom;*/
/*    width: 100%;*/
/*}*/

.footer {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    padding-top: 29px;
    background-color: #363b3f;
    position: revert;
    margin-top: auto;
    display: block;
    width: 100%;
    height: 200px;



}
* {
    margin: 0;
    padding: 0;
}

.box{
    margin-left: 10%;
}

.logo1 {
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
    width: 205px;
    /*height: 51px;*/
}
.logo2 {
    display: block;
    text-decoration: none;
    width: 100px;
    float: left;
    margin-right: 10px;
    /*height: 51px;*/
}


.list1{
    list-style-type: none;
    float: left;
    color: white;
}
.list2{
    list-style-type: none;
    float: left;
    color: white;
}
.list3{
    list-style-type: none;
    float: left;
    color: white;
}
.listAll{
    list-style-type: none;
    float: left;
    color: white;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .footer{
        height: 160px;
        padding-left: 0px;
    }
    .list1{
    list-style-type: none;
    float: none;
    color: white;
        font-size: 8pt;
}
    .list2{
        display: none;
    }
    .list3{
        display: none;
    }
    .listAll{
        margin-right: 10px;
    }
    .logo1 {
    margin-bottom: 15px;
    display: block;
    text-decoration: none;
    width: 140px;
    /*height: 51px;*/
}
.logo2 {
    display: block;
    text-decoration: none;
    width: 60px;
    float: left;
    margin-right: 10px;
    /*height: 51px;*/
}
}
