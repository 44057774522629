.manualH1{
    text-align: center;
    margin-bottom: 40px;
}
.manualH2{
    text-align: center;
    margin-bottom: 40px;
}

.imgScren{width: 80%}

.manualP{
    font-size: 16pt;
    margin-top: 20px;
}