.imgReport{
    margin-left:30%;
    width: 40%;

}
.generalBody{
    display: flex;
}
.CardReports{
width: 285px;
height: 300px;
margin: 5px;
display: inline-block;
}
.overlayDiv {
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    z-index:999;
}
.tableReport {
    width: 99%;
    margin-bottom: 20px;
    margin-left: 5px;
    border: 1px solid rgba(5, 5, 5, 0.45);
    border-collapse: collapse;
}
.tableReport th {
    font-weight: bold;
    padding: 5px;
    text-align: center;
    background: #efefef;
    border: 1px solid rgba(5, 5, 5, 0.45);
}
.tableReport td {

    border: 1px solid rgba(5, 5, 5, 0.45);
    padding: 5px;
}
.tableReport tr:hover{
    background-color: #dcddde;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .generalBody{
        display: block !important;
    }
    .CardReports{
        width: 90%;
        height: 300px;
        margin-left: 5%;
        margin-right: 5%;
        display: inline-block;
    }
}