@charset "UTF-8";
.bodyString{
    padding: 10px;
    background-color: rgba(234, 235, 238, 0.74);
    margin: 5px;
    border-radius: 8px;
    border: rgba(30, 33, 37, 0.32) solid 1px;
    /*box-shadow: 10px 5px 5px rgba(33, 33, 33, 0.09);*/
}

.shetsActiv{
    color: black;
}

.shetsActiv:hover{
    color: rgba(13, 110, 253, 0.91);
}

.container {
    position: relative;
    display: block;
    width: 100%;
}
details {
    position: relative;
    width: auto;
    height: auto;
    overflow: hidden;
    /*max-height: 2.5em !important;*/
    transition: all 0.1s ease;
}
details ::-webkit-details-marker {
    display: none;
}
details > summary {
    position: relative;
    /*margin-top: 0.25em;*/
    /*color: #ffffff;*/
    /*padding: 0.1em 0.5em 0.2em;*/
    /*background: rgba(39, 111, 217, 0.97);*/
}
/*details > summary:before {*/
/*    content: '⊞';*/
/*    color: #eee;*/
/*    font-size: 0.5em;*/
/*    margin-right: 0.5em;*/
/*}*/
/*details[open] > summary {*/
/*    background: #66e;*/
/*    width: 30px;*/
/*    color: #eee;*/
/*}*/
/*details[open] > summary:before {*/
/*    content: '⊟';*/
/*    color: #ccc;*/
/*}*/
.folder {

    /*margin: 0 1em 0.5em 0.90em;*/
    padding-left: 35px;
    border-left: #ffffff inset ;

    /*border-bottom: 0.1em #0d6efc solid;*/
}
.folder p {
    margin: 0;
}
details[open] {
    height: auto;
    max-height: 100% !important;
    transition: all 1.2s ease;
}
details:not([open]) .folder {
    height: 0px !important;
}
.form-check-input{
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
    margin-left: 5px;

    padding: 0;
}
.buttomPost{
    margin-left: 20px;
    margin-bottom: 20px;
}
.buttomString{
    margin-left: 20px;
    margin-bottom: 20px;
}
.tbDoc{
    border: 1px solid rgba(5, 5, 5, 0.45);

}

.tbDoc th{
    border: 1px solid rgba(5, 5, 5, 0.45);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.tbDoc td{
    text-align: center;
    border: 1px solid rgba(5, 5, 5, 0.45);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
details summary { list-style-type: none; } /* Firefox */
details summary::-webkit-details-marker { display: none; } /* Chrome */
details summary::marker { display: none; }