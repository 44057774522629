.upScroll{
    position: fixed;
    /* цвет фона */
    /* расстояние от правого края окна браузера */
    right: 20px;
    /* расстояние от нижнего края окна браузера */
    bottom: 0;
    /* скругление верхнего левого угла */
    border-top-left-radius: 8px;
    /* скругление верхнего правого угла */
    border-top-right-radius: 8px;
    /* вид курсора */
    cursor: pointer;
    margin-bottom: 50px;
    margin-right: 30px;
    /* отображение элемента как flex */
    display: flex;
    /* выравниваем элементы внутри элемента по центру вдоль поперечной оси */
    align-items: center;
    /* выравниваем элементы внутри элемента по центру вдоль главной оси */
    justify-content: center;
    /* ширина элемента */
    width: 60px;
    /* высота элемента */
    height: 50px;
}