/*@import url('https://fonts.googleapis.com/css?family=Exo:400,700');*/
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
.section
{
    flex-flow: column wrap;

    position:relative;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    display: block;
}
.section div.content h2
{
    font-size: 5em;
    margin:0;
    padding:0;
}
.section div.content p
{
    font-size:1.5em;
}
.headerContent{
    margin-bottom: 100px;
}
.logoAuth{
    width: 300px;
}
.h1Auth{
color: white;
}
.contentDivAut{
    position: relative;
    width: 1000px;
    height: 2400px;
    background-color: rgb(255, 255, 255);
}

.authAnimated {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 400px;
    background-color: rgb(255, 255, 255);
    font-family: Roboto;
    overflow: hidden;
}
.authAnimated:before, .authAnimated:after {

    content: "";
    position: absolute;
    left: 50%;
    min-width: 300vw;
    min-height: 300vw;
    background-color: #0495fd;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.authAnimated:before {
    bottom: 10vh;
    border-radius: 45%;
    animation-duration: 20s;
}
.authAnimated:after {
    bottom: 7vh;
    opacity: 0.5;
    border-radius: 47%;
    animation-duration: 20s;
}
@keyframes rotate {
    0% {
        transform: translate(-50%, 0) rotateZ(0deg);
    }
    50% {
        transform: translate(-50%, -2%) rotateZ(180deg);
    }
    100% {
        transform: translate(-50%, 0%) rotateZ(360deg);
    }
}
h1, h2 {
    color: #00487c;
    z-index: 10;
    margin: 0;
    font-weight: 300;
    line-height: 1.3;
    text-align: center;
}
.pHead{
    text-align: center;
    color: rgba(255,255,255,0.85);
    font-size: 22px;
}
.card1{
    color: white;
    margin-left: 100px;
    display: flex;
    flex-flow: revert;

    margin-bottom: 100px;
}
.divHomeAuth{
    z-index: 10;
    padding-bottom: 70px;
}
.LogoHeader{
    z-index: 10;
    margin: 0;
    font-weight: 300;
    line-height: 1.3;
    text-align: center;
    width: 400px;
}

.cardsAuth {
    /*float: left;*/
    font-size: 20px;
    width: 825px;
    height: 300px;
    /*background-color: rgba(0, 127, 255, 0);*/
    padding: 20px;
    border-radius: 8px;
}
.cardsAuthLine{
    font-size: 20px;
    width: 825px;
    height: 320px;
    /*background-color: rgba(0, 127, 255, 0);*/
    padding: 20px;
    border-radius: 8px;
}
.bodyTextCard1{
/*order: 1;*/
    /*float: left;*/
    font-size: 20px;
    width: 825px;
    height: 300px;
    margin-right: 30px;
    margin-top: 80px;

    background-color: rgba(4,149,253,0.85);
    padding: 20px;
    border-radius: 8px;

}
.bodyTextCard2 {
    /*float: left;*/
    font-size: 20px;
    width: 825px;
    height: 300px;
    margin-right: 60px;
    margin-top: 55px;

    background-color: rgba(4, 149, 253, 0.85);
    padding: 20px;
    border-radius: 8px;

}
.bodyTextCard3{
    /*float: left;*/
    font-size: 20px;
    width: 825px;
    height: 300px;
    margin-right: 30px;
    margin-top: 80px;

    background-color: rgba(4,149,253,0.85);
    padding: 20px;
    border-radius: 8px;
}
.bodyTextCard4{
 float: left;
    width: 100%;
}
.bifCardsAuthFooter{
    margin-top: 20px;
}
.img5{
    width: 450px;
    margin-left: 280px;
}
.img3{
    /*order: 2;*/
    width: 450px;
}
.img1{
    width: 450px;
}
.img4{
    width: 450px;
}
.img6{
    display: none;
}
.card2{
  color: white;
    margin-left: 30px;
    display: flex;
    margin-bottom: 100px;
}
.card3{
    color: white;
    margin-left: 100px;
    display: flex;
    margin-bottom: 100px;
}
.bifCardsAuth {
    font-size: 20px;
    margin-left: 100px;
    color: #ffffff;
    width: 800px;
    height: 250px;
    background-color: rgb(4, 148, 252);
    padding: 20px;
    border-radius: 8px;
}
.h1LogoFooter{
    text-align: center;
    color: #2d2a2a;
    font-size: 40pt;
}
.logoBlue{
    width: 500px;
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

.contentDivAut{
    width: 100%;
    height: 3350px;
}
    .authAnimated{
        /*display: none;*/
       /*min-width: 320px;*/
    width: 100%;

    }
   .authAnimated:before, .authAnimated:after {

    content: "";
    position: absolute;
    left: 50%;
    min-width: 100vw;
    min-height: 100vw;
    background-color: #0495fd;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.authAnimated:before {
    bottom: 10vh;
    border-radius: 45%;
    animation-duration: 20s;
}
.authAnimated:after {
    bottom: 7vh;
    opacity: 0.5;
    border-radius: 47%;
    animation-duration: 20s;
}
    .LogoHeader{
        width: 300px;
    }
    .card1{
        display: flex;
        flex-flow: column wrap;

        color: white;
        margin-left: 0px;
        /*display: revert;*/
        margin-bottom: 100px;
    }
    .bodyTextCard1{
        /*float: left;*/
        order: 1;
        font-size: 20px;
    width: 100%;
        height: 300px;
        /*margin-right: 30px;*/
        margin-right: 0;
        margin-top: 80px;
        background-color: rgba(4,149,253,0.85);
        padding: 20px;
        border-radius: 0px;

    }
    .img3{
    width: 100%;
    }
    .card2{
        color: white;
        margin-left: 0px;
        display: revert;
        margin-bottom: 100px;
    }
    .bodyTextCard2{
        /*float: left;*/
        font-size: 20px;
    width: 100%;
        height: 400px;
        /*margin-right: 30px;*/
        margin-right: 0;
        margin-top: 80px;
        background-color: rgba(4,149,253,0.85);
        padding: 20px;
        border-radius: 0px;

    }
    .img1{
    width: 100%;
    }
    .card3{
        display: flex;
        flex-flow: column wrap;

        color: white;
        margin-left: 0px;
        /*display: revert;*/
        margin-bottom: 100px;
    }
    .bodyTextCard3{
        /*float: left;*/
        order: 1;
        font-size: 20px;
    width: 100%;
        height: 300px;
        /*margin-right: 30px;*/
        margin-right: 0;
        margin-top: 80px;
        background-color: rgba(4,149,253,0.85);
        padding: 20px;
        border-radius: 0px;

    }
    .img4{
    width: 100%;
    }
    .bifCardsAuth {
        font-size: 20px;
        margin-left: 0px;

        color: #ffffff;
    width: 100%;
        height: 500px;
        background-color: rgb(4, 148, 252);
        padding: 20px;
        border-radius: 0px;
    }
    .h1LogoFooter{
        text-align: center;
        color: #2d2a2a;
        font-size: 40pt;
    }
    .logoBlue{
display: none;
    }
    .img5{
      display: none;
    }
    .h1LogoFooter{
        text-align: center;
        color: #2d2a2a;
        font-size: 40pt;
    }
    .img6{
        display: flex;

        width: 100%;
    }
}